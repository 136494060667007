import React, {useEffect,useState,useContext} from 'react';
import { FabContext } from "../../context/FabContext";
import Masonry from 'react-masonry-css';
import Layout from "../../components/layout";
import './style.scss';

const Brides = () => {

    const {brides,setLocal} = useContext(FabContext);
    const [feature,setFeat] = useState();
    const [visibility, setViz] = useState(false);

    useEffect(()=>{ setLocal('brides');},[]);

    function changeFeature(index) { 
        setFeat(brides[index]);
        setViz(true);
        setLocal('bride-exp');
    }
    function featureOff() { 
        setFeat('');
        setViz(false);
        setLocal('brides');
    }

    const bridalGrid = brides.map((pic,index) =>  
            <img 
                onClick={() => changeFeature(index) }
                key={index}
                role="gridcell"
                alt={pic.url}
                src={`../images/brides/${pic.url}`} />
        )

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
      };

    return(
        <Layout>
            <div  className="brides" >
                { visibility && 
                        <div role="main" onClick={() => featureOff() } className="brides__feature">
                                    <svg className="brides__feature__close" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 329 329" ><path transform="rotate(-45.001 164.499 164.503)" className="st0" d="M-55.9 152.3h440.8v24.5H-55.9z"/><path transform="rotate(-45.001 164.499 164.503)" className="st0" d="M152.3-55.9h24.5v440.8h-24.5z"/></svg>
                                    <img alt={feature.url} className="brides__feature__image" src={`../images/brides/${feature.url}`}></img>
                                    {feature.jamie && <h5  className="brides__feature__attribution">&copy; Jamie Levine Photography</h5> }
                        </div>
                }
                <div className="brides__intro">
                    <h1 className="brides__intro__headline">Brides</h1>
                    <p className="brides__intro__copy">I have had the pleasure of helping brides all over the world find their dream gowns. There is nothing more rewarding than seeing a bride radiate confidence and I am so grateful to be able to showcase my beautiful brides and how happy they were on their wedding day!</p>
                </div>

                    <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="brides__gallery"
                    columnClassName="my-masonry-grid_column">
                    {bridalGrid}
                    </Masonry>
            </div>
        </Layout>
    )
}
export default Brides;
